@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  li {
    @apply p-4 cursor-pointer
  }

  button {
    @apply text-white border bg-indigo-600 border-indigo-600 rounded-md
  }

}